.greeting__background {
  background-image: url("https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80");
  height: 100vh;

  width: 100%;
  /* margin-top: -1in; */
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  /* background-position: center center; */
}

.greeting__divider {
  display: table;
  width: 100%;
  height: 85%;
}

.greeting_section {
  display: table-cell;
  vertical-align: middle;
}

.greeting_wrapper {
  display: flex;
  flex-direction: column;
}

.greeting_wrapper>h3 {
  /* margin-top: 35vh; */
  font-size: 60px;
  font-family: Poppins;
  font-weight: 520;
  color: #031b1f;
  text-align: center;
  line-height: 50px;
  display: table-cell;
  vertical-align: middle;
}

.greeting__line01 {
  margin-top: 15px;
  font-family: Poppins;
  font-size: 25px;
  color: #031b1f;
  font-weight: normal;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.greeting__line02 {
  overflow: hidden;
  /* Ensures the content is not revealed until the animation */
  /* border-right: 0.15em solid orange; */
  white-space: nowrap;
  /* Keeps the content on a single line */
  margin: 0 auto;
  /* Gives that scrolling effect as the typing happens */

  font-weight: bold;
  font-family: Poppins;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;

  border-right: 0.15em solid orange;

  animation: typing 8s steps(40, end), blink-caret 0.6s step-end infinite;

  /* If animation is running then for the next iteration add a delay of 15s */

  /* animation-delay: 0s, 20s; */
  /* animation-iteration-count: infinite; */
  color: #031b1f;
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 13.2em;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {

  from,
  to {
    border-color: transparent;
  }

  60% {
    border-color: orange;
  }
}

.aboutme__container {
  padding: 80px 0px;
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  justify-content: space-around;
  /* margin-left: 10%; */
  background-image: linear-gradient(120deg, #fdfbfb 0%, #dededf 100%);
}

.about__img {
  max-width: 22%;
  margin-top: 40px;
  border-radius: 10px;
  -moz-box-shadow: -10px 10px 5px #ccc;
  -webkit-box-shadow: -10px 12px 5px #ccc;
  height: 500px;
  margin-left: 9%;
  margin-right: 4%;
  object-fit: cover;
}

.about__container {
  padding: 40px 0px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin-right: 4.197%;
  /* margin-top: 30px; */
}

.about__container>h2 {
  margin-bottom: 30px;
}

.about__container>p {
  font-size: 17px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.about_contact {
  margin-top: 25px;
  display: flex;
}

.a_contact_items {
  display: flex;
  align-content: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.about_header {
  font-weight: 600;
  margin-left: 1vw;
  text-align: center;
}

.first_word {
  display: flex;
  align-content: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-bottom: 20px;
}

.a_contact_items_l01 {
  display: flex;
  align-content: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-bottom: 20px;
  margin-left: 10em;
}

.a_contact_items_l02 {
  display: flex;
  align-content: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-left: 16.7em;
  margin-bottom: 2.5vh;
}

.skills_container {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  margin-top: 20px;
}

.skill_list {
  min-width: 109px;
  padding: 10px;
  height: 40px;
  border-radius: 100px;
  border: none;
  /* cursor: pointer; */
  transition: all 0.5s ease;
  background-color: rgb(14, 14, 14);
  color: white;
  white-space: nowrap;
  outline: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  margin-right: 15px;
  margin-bottom: 50px;
  margin-top: 15px;
}

.skill_list:hover {
  /* background: transparent;
  color: black; */
  transform: scale(1.03);
  box-shadow: 0px 0px 10px #000;
  justify-content: baseline;
  cursor: pointer;
}

.cv_link>button {
  min-width: 140px;
  height: 45px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: all 0.8s ease;
  background-color: #4357ad;
  color: white;
  white-space: nowrap;
  outline: 0px;
  font-size: 16px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  transition: all 0.3s ease !important;
  font-weight: bold;
}

.cv_link>button:hover {
  border-radius: 20px;
  transform: scale(1.09) !important;
}

.cv_link>button:active {
  border-radius: 1px;
}

.whatI_canDo {
  padding: 80px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  max-width: 100%;
  height: 100%;
  align-items: center !important;
  /* background-image: linear-gradient(358deg, #93a5cf 0%, #edecec 100%); */
  background: #c9ccd3;
  background-image: linear-gradient(-180deg,
      rgba(255, 255, 255, 0.644) 0%,
      rgba(0, 0, 0, 0.5) 100%);
  background-blend-mode: lighten;
}

.experience_container {
  padding: 80px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  max-width: 100%;
  height: 100%;
  align-items: center !important;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #dededf 100%);
}

.projects_container {
  padding: 80px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  max-width: 100%;
  height: 100%;
  align-items: center !important;
  /* background-image: linear-gradient(358deg, #93a5cf 0%, #edecec 100%); */
  background: #c9ccd3;
  background-image: linear-gradient(-180deg,
      rgba(255, 255, 255, 0.644) 0%,
      rgba(0, 0, 0, 0.5) 100%);
  background-blend-mode: lighten;
}

.whatI_canDo>h1,
.projects_container>h1,
.experience_container>h1,
.contact_container>h1 {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  position: relative;
  text-transform: center;
  margin-bottom: 55px;
}

.whatI_canDo>h1>span,
.experience_container>h1>span,
.projects_container>h1>span {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.whatI_canDo>h1>span>hr,
.experience_container>h1>span>hr,
.projects_container>h1>span>hr {
  width: 40px;
  height: 1px !important;
  margin: 0px 10px;
  border: 1.5px solid rgb(0, 0, 0);
  background-color: rgb(0, 0, 0);
  border-radius: 20%;
}

.cardContainer,
.projectCardContainer {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 100px;
  grid-auto-rows: minmax(250px, auto);
  grid-auto-flow: dense;
  /* background-color: #4357ad; */
}

.projectCardContainer {
  gap: 50px;
}

.experience_wrapper {
  margin: 0 auto;
  width: 90%;
}

.experience_list {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
}

.experience_list>li {
  margin-left: 20px;
  margin-bottom: 10px;
}

.experience_position {
  margin-top: 5px;
  margin-bottom: 20px;
}

.position_time {
  display: block;
  float: right;
  font-weight: normal;
  font-size: 17px;
}

.position {
  font-weight: 400;
}

.contact_container {
  background: #212121;
  color: white;
  padding: 80px 0px;
  display: block;
  box-sizing: border-box;
  max-width: 100%;
}

.contact_container>h2 {
  margin-top: -40px;
  text-align: center;
  color: white;
}

.icons {
  color: white;
  margin-left: 10px;
}

@media only screen and (max-width: 1980px) {

  .contact_container>h2 {
    margin-top: -45px;
  }

  .skill_list {
    margin-bottom: -7px;
  }

  .cv_link>button {
    margin-top: 50px;
  }

  .cardContainer,
  .projectCardContainer {
    width: 80%;
  }

  .experience_wrapper {
    width: 80%;
  }

  .cardContainer,
  .projectCardContainer {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 50px;
    /* background-color: #4357ad; */
  }
}


@media only screen and (max-width: 1460px) {
  .skill_list {
    margin-bottom: -7px;
  }

  .cv_link>button {
    margin-top: 25px;
  }

  .a_contact_items_l02 {
    margin-left: 15.7em;
  }

  .a_contact_items_l01 {
    margin-left: 9em;
  }

  .experience_wrapper {
    width: 100%;
  }

  .cardContainer,
  .projectCardContainer {
    width: 90%;
  }

  .experience_wrapper {
    width: 90%;
  }

}


@media only screen and (max-width: 1130px) {
  .aboutme__container {
    display: block;
    margin-top: -5px;
  }

  .about__img {
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 400px;
    margin-top: -15px;
    margin-bottom: -25px;
  }

  .about__container {
    margin-left: 13px;
    margin-top: 15px;
  }

  .skill_list {
    margin-bottom: -7px;
  }

  .skill_list {
    margin-bottom: -7px;
  }

  .cv_link>button {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 1030px) {
  .aboutme__container {
    display: block;
    margin-top: -5px;
  }

  .about__img {
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 400px;
    margin-top: -15px;
    margin-bottom: -25px;
  }

  .about__container {
    margin-left: 13px;
  }

  .skill_list {
    margin-bottom: -7px;
  }
}

@media only screen and (max-width: 1010px) {
  .aboutme__container {
    display: block;
    margin-top: -5px;
  }

  .about__img {
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 400px;
    margin-top: -15px;
    margin-bottom: -25px;
  }

  .about__container {
    margin-left: 13px;
  }

  .skill_list {
    margin-bottom: -7px;
  }
}

@media only screen and (max-width: 915px) {
  .aboutme__container {
    display: block;
    margin-top: -5px;
  }

  .about__img {
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 400px;
    margin-top: -15px;
    margin-bottom: -25px;
  }

  .about__container {
    margin-left: 13px;
  }

  .skill_list {
    margin-bottom: -7px;
  }

  .contact_container>h2 {
    margin-top: -45px;
  }

}

@media only screen and (max-width: 765px) {
  .aboutme__container {
    display: block;
    margin-top: -5px;
  }

  .about__img {
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 400px;
    margin-top: -15px;
    margin-bottom: -25px;
  }

  .about__container {
    margin-left: 13px;
  }

  .a_contact_items_l01 {
    margin-left: 10em;
  }

  .a_contact_items_l02 {
    margin-left: 16.7em;
  }

  .skill_list {
    margin-bottom: -7px;
  }

  .cv_link>button {
    margin-top: 25px;
  }

  /* #F2F1F1 */
  .experience_container>h1::after {
    background-image: linear-gradient(283deg, #efeeef 0%, #f2f0f1 100%);
  }

  .whatI_canDo>h1::after {
    background-image: linear-gradient(2deg, #dfe0e3 0%, #e5e6e9 100%);
  }

  .projects_container>h1::after {
    background: #e7e8ea;
    background-image: linear-gradient(-180deg, #eaebee 0%, #c7cad1 100%);
    background-blend-mode: lighten;
  }

  .greeting__divider>h3 {
    font-size: 50px;
  }

  .greeting__line01 {
    font-size: 22px;
  }

  .contact_container>h2 {
    margin-top: -45px;
  }

}

@media only screen and (max-width: 705px) {

  .a_contact_items_l01 {
    margin-left: 9em;
  }

  .a_contact_items_l02 {
    margin-left: 14.6em;
  }

  .contact_container>h2 {
    margin-top: -45px;
  }

  .greeting_wrapper>h3 {
    font-size: 40px;
  }

  .greeting__line01 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 650px) {
  .a_contact_items_l01 {
    margin-left: 2em;
  }

  .a_contact_items_l02 {
    margin-left: 1em;
  }

  .contact_container>h2 {
    margin-top: -45px;
  }

  .a_contact_items {
    display: block;
  }

  .a_contact_items_l01 {
    margin-left: 0px;
    margin-top: 15px;

    display: flex;
  }

  .a_contact_items_l02 {
    margin-left: 0px;
    margin-top: 15px;
    display: flex;
  }

  .whatI_canDo>h1::after,
  .projects_container>h1::after,
  .experience_container>h1::after {
    font-size: 28px;
  }

  .position_time {
    display: none;
  }
}


@media only screen and (max-width: 430px) {
  .skill_list {
    min-width: 95px;
    font-size: 12px;
  }

  .a_contact_items_l01 {
    font-size: 15px;
    margin-left: 2em;
  }

  .a_contact_items_l02 {
    margin-left: 1em;
  }

  .contact_container>h2 {
    margin-top: -45px;
  }

  .a_contact_items {
    display: block;
  }

  .a_contact_items_l01 {
    margin-left: 0px;
    margin-top: 15px;

    display: flex;
  }

  .a_contact_items_l02 {
    margin-left: 0px;
    margin-top: 15px;
    display: flex;
  }

  .greeting_wrapper>h3 {
    font-size: 35px;
  }

  .greeting__line01 {
    font-size: 18px;
  }

  .cardContainer,
  .projectCardContainer {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 50px;
    /* background-color: #4357ad; */
  }

  .experience_wrapper {
    width: 100%;
  }

}